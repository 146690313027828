import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { userNotAuthorised} from '../authorities'


 function PrivateRoute({ Component, authorities, currentUser, isAuthenticated, ...rest }) {

  const renderRedirect = (props) => {

    if(!isAuthenticated) {
    // not logged in so redirect to login page with the return url
      return <Redirect to={{pathname: '/login', search: props.location.search, state: { from: props.location }}} />;
    }

    if( userNotAuthorised(currentUser, authorities)) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/'}} />  
    }

     return (<Component {...props} />)
  
    };

  return (
    <Route {...rest} render={renderRedirect} />
  );
}

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
  currentUser: authentication.account
});

export default connect(mapStateToProps)(PrivateRoute);
