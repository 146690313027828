import React from 'react';
import {Card } from 'react-bootstrap';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    static getDerivedStateFromError(error, errorInfo) {
        return {
            error,
            errorInfo
        }
    }

    render() {
        if (this.state.errorInfo) {

            //Allow details to be seen if in Development
            
            const errorDetails = process.env.NODE_ENV === 'development' ? (
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    <h4 className="mt-3"> <i className="fa fa-bug pr-2" /> {this.state.error && this.state.error.toString()} </h4>
                    {this.state.errorInfo.componentStack}
                </details>) :
                (undefined);

            return (
                <>

                    <Card className="shadow-3">
                        <Card.Body >
                            <Card.Title as="h3">
                                <i className="fa fa-exclamation-triangle mr-3" />  An unexpected error has occured.
                            </Card.Title>
                            {errorDetails}
                        </Card.Body>
                    </Card>
                </>
            );
        }

        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;