export const StorageType = {
    SESSION: 0,
    LOCAL: 1
}

/**
 * Get either localStorage or sessionStorage
 * @param type storage type
 */
export const getStorage = function (type) {
    if (type === 0 /* SESSION */) {
        return window.sessionStorage;
    }
    return window.localStorage;
};
  
  /**
   * Set an item into storage
   * @param type storage type
   * @param key key to set
   * @param value value to set
   */
  const setItem = (type) => (key, value) => {
    getStorage(type).setItem(key, JSON.stringify(value));
  };
  
  /**
   * Get an item from storage
   * @param type storage type
   * @param key key to get
   * @param defaultVal value to return if key doesnt exist
   */
  const getItem = (type) => (key, defaultVal) => {
    const val = getStorage(type).getItem(key);
    if (!val || val === 'undefined') return defaultVal;
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  };
  
  /**
   * Remove item from storage
   * @param type storage type
   * @param key key to remove
   */
  const removeItem = (type) => (key) => {
    getStorage(type).removeItem(key);
  };

export const Storage = {
    session: {
        get: getItem(0 /* SESSION */),
        set: setItem(0 /* SESSION */),
        remove: removeItem(0 /* SESSION */)
    },
    local: {
        get: getItem(1 /* LOCAL */),
        set: setItem(1 /* LOCAL */),
        remove: removeItem(1 /* LOCAL */)
    }
};