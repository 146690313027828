import { createStore, applyMiddleware, compose, bindActionCreators } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import errorMiddleware from './middleware/error-middleware'
import rootReducer from './reducers/index';

import { setupAxiosInterceptors } from '../modules/config';
import { clearAuthentication } from '../modules/authentication';

const defaultMiddlewares = [
    thunk,
    errorMiddleware, 
    promiseMiddleware];

const composedMiddlewares = () => compose(applyMiddleware(...defaultMiddlewares));

const store = createStore(rootReducer, composedMiddlewares());

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

export default store ;  