import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { withRouter} from 'react-router';
import Loadable from 'react-loadable';

import Loader from '../layout/main/Loader'
import ScrollToTop from '../layout/main/ScrollToTop';
import routes from '../../route';
import { PrivateRoute, getSessionFromStorage } from '../authentication';


const AdminLayout = Loadable({
    loader: () => import('../layout/main/AdminLayout'),
    loading: Loader
});

class App extends Component {
    
    componentDidMount(){
        this.props.getSessionFromStorage();
    }
 
    render() {
        const menu = routes.map((route, index) => {

            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => {
                        return <route.component {...props} />
                    }} 
                    
                    />
            ) : (null);
        });

        return (
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {menu}
                            <PrivateRoute  path="/"  Component={AdminLayout}  />            
                        </Switch>
                    </Suspense>
                </ScrollToTop>
        );
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.authentication.account,
        isAuthenticated: state.authentication.isAuthenticated
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSessionFromStorage: () => dispatch(getSessionFromStorage())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));