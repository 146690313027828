export const Authority = {
    USER: 'ROLE_USER',
    APPROVER: 'ROLE_APPROVER',
    ADMIN: 'ROLE_ADMIN',
    PROJECT_MANAGER: 'ROLE_PROJECT_MANAGER'
};

export const Roles = [authority(Authority.USER, 'Time Capturer'), authority(Authority.ADMIN, 'Admin'), authority(Authority.APPROVER, 'Approver'), authority(Authority.PROJECT_MANAGER, 'Project Manager')];


export const userNotAuthorised = (user, authorities) => {
    return authorities && !authorities.some(a => user.authorities.indexOf(a) >= 0);
};

export const userAuthorised = (user, authorities) => {
    return authorities && authorities.some(a => user.authorities.indexOf(a) >= 0);
};

function authority(value, label) {
    return {value, label};
}
