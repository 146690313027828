import { faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { AUTH_TOKEN_KEY } from '../authentication';
import { Storage } from '../util';
import REST_CONFIG from './rest-config';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = REST_CONFIG.BASE_URL;

export const setupAxiosInterceptors = onUnauthenticated => {
    const onRequestSuccess = config => {
        const token = Storage.local.get(AUTH_TOKEN_KEY) || Storage.session.get(AUTH_TOKEN_KEY);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };

    const onResponseSuccess = response => response;

    const onResponseError = err => {
        let icon =  <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2"/>;
        let message = null;
        
        if (!err.response) {
            message = <>{icon} Please check your internet connection.</>;
            toast.error(message);
        } else {
            const status = err.status || (err.response ? err.response.status : 0);
            
            if (status === 403 || status === 401) {
                onUnauthenticated();
                icon = <FontAwesomeIcon icon={faLock} className="mx-2"/>;
            }

            if (err.response.data.violations) {
                const violations = err.response.data.violations;
                const errorMessages = violations.map(violation => violation.message);
                const combinedErrorMessage = errorMessages.join('\n');
                toast.error(combinedErrorMessage);
            } else {
                message = <>{icon} {err.response.data.title}</>;
                toast.error(message);
            }
        }
        return Promise.reject(err);
    };

    axios.interceptors.request.use(onRequestSuccess);

    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
