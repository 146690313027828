import axios from 'axios';
import {toast} from 'react-toastify'

import { REQUEST, SUCCESS, FAILURE } from '../../../store/actions/requests';

export const ACTION_TYPES = {
  RESET_PASSWORD_INIT: 'passwordReset/RESET_PASSWORD_INIT',
  RESET_PASSWORD_FINISH: 'passwordReset/RESET_PASSWORD_FINISH',
  RESET: 'passwordReset/RESET',
  REDIRECT: 'passwordReset/REDIRECT',
};

const initialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordFailure: false
};


// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
      case ACTION_TYPES.REDIRECT:
          return {
            redirectTo: action.payload
          };
    case REQUEST(ACTION_TYPES.RESET_PASSWORD_FINISH):
    case REQUEST(ACTION_TYPES.RESET_PASSWORD_INIT):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.RESET_PASSWORD_FINISH):
    case FAILURE(ACTION_TYPES.RESET_PASSWORD_INIT):
      return {
        ...initialState,
        loading: false,
        resetPasswordFailure: true
      };
    case SUCCESS(ACTION_TYPES.RESET_PASSWORD_FINISH):
    case SUCCESS(ACTION_TYPES.RESET_PASSWORD_INIT):
      return {
        ...initialState,
        loading: false,
        resetPasswordSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'account/reset-password';

// Actions
export const handlePasswordResetInit = email => ({
  type: ACTION_TYPES.RESET_PASSWORD_INIT,
  // If the content-type isn't set that way, axios will try to encode the body and thus modify the data sent to the server.
  payload: axios.post(`${apiUrl}/init`, email, { headers: { 'Content-Type': 'text/plain' } }),
  meta: {
    successMessage: 'Password successfully reset',
    errorMessage: 'Password reset key not found.'
  }
});

export const handlePasswordResetFinish = (key, newPassword) => ({
  type: ACTION_TYPES.RESET_PASSWORD_FINISH,
  payload: axios.post(`${apiUrl}/finish`, { key, newPassword }),
  meta: {
    successMessage: 'Password successfully reset'
  }
});

export const requestResetAndRedirect = (email, history) => async (dispatch, getState) => {
  await dispatch(handlePasswordResetInit(email));
  toast.success('An email has been sent to reset your password.', { autoClose: 3000, hideProgressBar: true });
  history.push('/login');
}

export const finishResetAndRedirect = (key, newPassword, history) => async (dispatch, getState) => {
    await dispatch(handlePasswordResetFinish(key, newPassword));
    toast.success('Password successfully reset.', { autoClose: 3000, hideProgressBar: true });
    history.push('/login');
}

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

