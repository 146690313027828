/**
 * Get base path from current window location
 */
export const getBasePath = () => window.location.href.split('#')[0];

/**
 * Parse the header link element and return all links inside.
 * @param header header of link
 */
export const parseHeaderForLinks = header => {
    if (header.length === 0) {
        throw new Error('input must not be of zero length');
    }

    // Split parts by comma
    const parts = header.split(',');
    const links = {};

    // Parse each part into a named link
    parts.forEach(p => {
        const section = p.split(';');

        if (section.length !== 2) {
            throw new Error('section could not be split on ";"');
        }

        const url = section[0].replace(/<(.*)>/, '$1').trim();
        const queryString = {};

        url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => (queryString[$1] = $3));

        let page = queryString.page;

        if (typeof page === 'string') {
            page = parseInt(page, 10);
        }

        const name = section[1].replace(/rel="(.*)"/, '$1').trim();
        links[name] = page;
    });
    return links;
};

/**
 * Fetch an entry from URL params
 * @param name the param name to fetch
 * @param search the search part from react router location
 */
export const getUrlParameter = (name, search) => {
    const url = new URL(`http://localhost${search}`); // using a dummy url for parsing
    return url.searchParams.get(name) || '';
};

/**
 * Converts an objects properties into a URl query parameters string.
 * E.g. {name: 'Jane', surname: 'Doe'} will become 'name=Jane&surname=Doe'
 * @param requestParamsObject an object whose key-value pairs will be converted to a URL query parameters string
 * @returns query parameters pairs of a URL query based on an objects properties
 */
export const getRequestParamsFromObject = requestParamsObject => {
    return new URLSearchParams(requestParamsObject).toString();
};
/**
 * Creates a url query string from a path and an object whose properties are the query parameters.
 * E.g. path='submit' and queryParamsObject={name: 'Jane', surname: 'Doe'} will return 'submit?name=Jane&surname=Doe'
 * @param {*} path part of the url preceding the '?' in the query string
 * @param {*} requestParamsObject an object whose key-value pairs will be converted to a URL query parameters string
 */
export const getUrlQuery = (path, requestParamsObject) => {
    return `${path}?${getRequestParamsFromObject(requestParamsObject)}`;
};
