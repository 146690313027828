import { combineReducers } from 'redux';
import menu from '../../modules/layout/main/AdminLayout/menu.reducer';
import authentication from '../../modules/authentication/authentication.reducer'
import passwordReset from '../../modules/account/password-reset/password-reset.reducer'

const rootReducer = combineReducers({
    menu,
    authentication,
    passwordReset
});

export default rootReducer;