import React from 'react';
import { ErrorBoundary } from '../../error';
import PrivateRoute from './private-route';

export const PrivateErrorBoundaryRoute = ({ Component, ...rest }) => {
  const wrappedInErrorBoundary = props => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  if (!Component) throw new Error(`A component needs to be specified for path ${(rest).path}`);

  return <PrivateRoute {...rest} Component={wrappedInErrorBoundary} />;
};

export default PrivateErrorBoundaryRoute;
