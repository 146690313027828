import React from 'react';

const Login = React.lazy(() => import('./modules/authentication/login'));

const PasswordResetRequestPage = React.lazy(() => import('./modules/account/password-reset/init/password-reset-init')); 
const PasswordResetFinishPage = React.lazy(() => import('./modules/account/password-reset/finish/password-reset-finish'));

const route = [
    { path: '/login', exact: true, name: 'Login', component: Login },

    { path: '/reset/request', exact: true, name: 'Reset Password', component: PasswordResetRequestPage },
    { path: '/reset/finish', exact: true, name: 'Reset Password', component: PasswordResetFinishPage }
];

export default route;