export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function getMonday(date) {
    date = new Date(date);
    var day = date.getDay(),
        diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(date.setDate(diff));
}

export function shortIsoString(date) {
    const dateNumber = date.getDate();
    const monthNumber = (date.getMonth() + 1);

    const day = (dateNumber < 10) ? `0${dateNumber}` : dateNumber;
    const month = (monthNumber < 10) ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
    const year = date.getFullYear();
    
    return `${year}-${month}-${day}`;
}

export function MonthNameShort(date) {
    const months = {
        0: 'Jan', 1: 'Feb', 2: 'Mar', 3: 'Apr', 4: 'May', 5: 'Jun',
        6: 'Jul', 7: 'Aug', 8: 'Sep', 9: 'Oct', 10: 'Nov', 11: 'Dec'
    };
    return months[date.getMonth()];
}

export function MonthNameFull(date) {
    const months = {
        0: 'January', 1: 'Febuary', 2: 'March', 3: 'April', 4: 'May', 5: 'June',
        6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December'
    };
    return months[date.getMonth()];
}

export function WeekDayShort(date) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek[date.getDay()];
}

export function WeekDayFull(date) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[date.getDay()];
}

export function DateRange(startDate, endDate) {
    let d = new Date();
    let dateRange = [];
    for (d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        dateRange.push(new Date(d));
    }
    return dateRange;
}

export function todayAsString() {
    return new Date().toISOString().slice(0, 10);
}

export function isToday(date) {
    const today = new Date()
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};